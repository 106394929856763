<script setup>
import { onMounted, ref } from 'vue';

defineProps({
    hasError: {
        type: Boolean,
    },
});

const model = defineModel({
    type: String,
});

const input = ref(null);

// onMounted(() => {
//     if (input.value.hasAttribute('autofocus')) {
//         input.value.focus();
//     }
// });

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <select ref="input" v-model="model" class="select" :class="{ 'select-error': hasError }">
        <slot />
    </select>
</template>
